<template>
  <div class="patrol-strategy-container">
    <create-button
      text="创建巡检策略"
      v-permission="{action: 'ops.patrol_strategy.create', effect: 'disabled'}"
      style="width: 148px; height: 40px"
      @click="$router.push({ name: 'PatrolStrategyCreate' })"
    >
    </create-button>
    <reload-button
      @click="fetch()"
      style="position: absolute; right: 16px; top: 13px; z-index: 999"
    ></reload-button>
    <div
      style="
        background: #fff;
        border-radius: 8px;
        padding: 16px;
        margin-top: 16px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
      "
    >
      <div class="module-header" style="margin-bottom: 24px">巡检策略管理</div>

      <div class="search-container">
        <a-space>
          <span>策略名称:</span>
          <a-input-search
            class="input-search"
            placeholder="请输入搜索关键字"
            v-model="searchText"
            @search="search"
          ></a-input-search>
        </a-space>
        <a-space>
          <span>巡检状态:</span>
          <simple-select
            :options="statusOptions"
            :width="160"
            @input="v => (currentStatus = v.value)"
          ></simple-select>
        </a-space>
        <a-space>
          <span>资源类型:</span>
          <simple-select
            :options="sourceTypeOptions"
            @input="v => (currentSourceType = v.value)"
            :width="160"
          ></simple-select>
        </a-space>
        <a-space>
          <span>执行方式: </span>
          <simple-select
            :options="methodOptions"
            :width="160"
            @input="v => (currentMethod = v.value)"
          ></simple-select>
        </a-space>
      </div>

      <a-table
        class="custom-table  scroll-hidden-table "
        style="margin-top: 24px"
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        row-key="name"
        :scroll="{ x: scrollX }"
        :total="total"
      >
        <template slot="name" slot-scope="text, record">
          <a-tooltip placement="topLeft" :title="text">
            <a
              @click="
                $router.push({
                  name: 'PatrolStrategyReport',
                  params: { id: record.id }
                })
              "
              >{{ text }}</a
            >
          </a-tooltip>
        </template>
        <template slot="description" slot-scope="text">
          <a-tooltip placement="topLeft" :title="text">
            {{ text ? text : '-' }}
          </a-tooltip>
        </template>
        <template slot="sourceType" slot-scope="text">
          {{ $t(`source_type.${text}`) }}
        </template>
        <template slot="periodic" slot-scope="text">
          {{ text ? '按周期' : '仅一次' }}
        </template>
        <template slot="lastAt" slot-scope="text">
          {{ text || '-' }}
        </template>
        <template slot="nextAt" slot-scope="text">
          {{ text || '-' }}
        </template>
        <template slot="status" slot-scope="text">
          <patrol-strategy-tag :status="text"></patrol-strategy-tag>
        </template>
        <template slot="active" slot-scope="text, record">
          <a-switch :defaultChecked="text" @change="v => changeStatus(v, record)" :disabled="checkedDisabled"></a-switch>
        </template>
        <template slot="operation" slot-scope="text, record">
          <clone-button
            @click="
              $router.push({
                name: 'PatrolStrategyEdit',
                params: { id: record.id, isClone: true }
              })
            "></clone-button>
          <edit-button
          v-permission="{action: 'ops.patrol_strategy.update', effect: 'disabled'}"
            @click="
              $router.push({
                name: 'PatrolStrategyEdit',
                params: { id: record.id }
              })
            "
          ></edit-button>
          <delete-button v-permission="{action: 'ops.patrol_strategy.delete', effect: 'disabled'}" @confirm="handleDelete(record.id)"></delete-button>
        </template>
      </a-table>

      <div style="height: 32px; margin-top: 24px">
        <a-pagination
          :current="params.page"
          :pageSize="params.page_size"
          :show-size-changer="false"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="
            page => {
              params.page = page
              fetch()
            }
          "
          style="float: right"
        ></a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { updateCurrentPage } from '@/utils'
import { sourceTypes, patrolStrategyStatus } from '@/utils/const'
import {
  getPatrolStrategyList,
  deletePatrolStrategy,
  updatePatrolStrategy
} from '@/api/patrol-strategy.js'
import SimpleSelect from '@/components/select/SimpleSelect'
import PatrolStrategyTag from '@/components/tag/PatrolStrategyTag'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import CloneButton from '@/components/button/CloneButton'
import ReloadButton from '@/components/button/ReloadButton.vue'

export default {
  name: 'PatrolStrategyList',
  components: {
    SimpleSelect,
    PatrolStrategyTag,
    DeleteButton,
    EditButton,
    CreateButton,
    CloneButton,
    ReloadButton
  },
  data () {
    return {
      currentSourceType: 'all',
      currentStatus: 'all',
      currentMethod: 'all',
      searchText: '',
      checkedDisabled: false,
      sourceTypeOptions: [
        { label: '全部资源', value: 'all' },
        ...sourceTypes.map(item => {
          return { label: this.$t(`source_type.${item}`), value: item }
        })
      ],
      statusOptions: [
        { label: '全部', value: 'all' },
        ...patrolStrategyStatus.map(item => {
          return {
            label: this.$t(`patrol_strategy_status.${item}`),
            value: item
          }
        })
      ],
      methodOptions: [
        { label: '全部方式', value: 'all' },
        { label: '仅一次', value: 'false' },
        { label: '按周期', value: 'true' }
      ],
      loading: false,
      columns: [
        {
          dataIndex: 'name',
          title: '策略名称',
          ellipsis: true,
          fixed: 'left',
          width: 200,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'source_type',
          title: '资源类型',
          width: 120,
          scopedSlots: {
            customRender: 'sourceType'
          }
        },
        {
          dataIndex: 'periodic',
          title: '执行方式',
          width: 100,
          scopedSlots: {
            customRender: 'periodic'
          }
        },
        {
          dataIndex: 'last_at',
          title: '最近执行时间',
          width: 200,
          scopedSlots: {
            customRender: 'lastAt'
          }
        },
        {
          dataIndex: 'next_at',
          title: '下次执行时间',
          width: 200,
          scopedSlots: {
            customRender: 'nextAt'
          }
        },
        {
          dataIndex: 'status',
          title: '巡检状态',
          width: 100,
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        },
        {
          dataIndex: 'active',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'active'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 240,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      dataSource: [],
      total: 0,
      params: {
        page: 1,
        page_size: 10
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch (update = false) {
      this.loading = true
      if (update) {
        this.params.page = updateCurrentPage(
          this.total,
          this.params.page,
          this.params.page_size
        )
      }
      getPatrolStrategyList(this.params).then(res => {
        this.dataSource = res.data.data
        this.total = res.data.total
      }).finally(() => { this.loading = false })
    },
    handleDelete (id) {
      deletePatrolStrategy(id).then(res => {
        this.$message.success(res.message)
        this.fetch(true)
      })
    },
    search (v) {
      this.params.name = v
      this.params.page = 1
      this.params.page_size = 10
      if (!v) delete this.params.name
      this.fetch()
    },
    changeStatus (active, item) {
      this.checkedDisabled = true
      updatePatrolStrategy(item.id, { active }).then(res => {
        this.$message.success(res.message)
        this.fetch()
      }).finally(() => {
        this.checkedDisabled = false
      })
    }
  },
  watch: {
    currentSourceType (v) {
      this.params.source_type = v
      this.params.page = 1
      this.params.page_size = 10
      if (v === 'all') delete this.params.source_type
      this.fetch()
    },
    currentStatus (v) {
      this.params.page = 1
      this.params.page_size = 10
      this.params.status = v
      if (v === 'all') delete this.params.status
      this.fetch()
    },
    currentMethod (v) {
      this.params.page = 1
      this.params.page_size = 10
      this.params.periodic = v
      if (v === 'all') delete this.params.periodic
      this.fetch()
    }
  }
}
</script>

<style lang="less">
.patrol-strategy-container {
  .search-container {
    display: flex;
    padding: 0 12px;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div {
      margin-bottom: 8px;
    }
    .input-search {
      width: 160px;
      .ant-input {
        background-color: #fff;
      }
    }
  }
}
</style>
